





































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, PropSync } from 'vue-property-decorator';
import { Photo } from '../models/models';

@Component
export default class LightBox extends Vue {
  @Prop(Function) hide!: () => void;
  @PropSync('index') imgIndex!: number;
  @Prop(Array) images!: Photo[];
  @Prop(Boolean) visible!: boolean;

  hideModal() {
    this.hide();
  }

  prev() {
    if (this.imgIndex > 0) {
      this.imgIndex = this.imgIndex - 1;
    }
  }

  next() {
    if (this.imgIndex < this.images.length - 1) {
      this.imgIndex = this.imgIndex + 1;
    }
  }
}
