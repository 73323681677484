var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('form',{staticClass:"form-horizontal"},[_c('FormGroup',{attrs:{"label":"Titel:"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.document.title),expression:"document.title"}],staticClass:"form-control",attrs:{"type":"text","required":""},domProps:{"value":(_vm.document.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.document, "title", $event.target.value)}}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('FormGroup',{attrs:{"label":"Beschreibung:"}},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.document.description),expression:"document.description"}],staticClass:"form-control",attrs:{"cols":"30","rows":"10"},domProps:{"value":(_vm.document.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.document, "description", $event.target.value)}}})]),_c('ValidationProvider',{ref:"drop",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DropArea',{attrs:{"single":"","files":_vm.document.formFile},on:{"dropped":_vm.dropped}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('FormGroup',{attrs:{"label":"Datum:"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.document.inputDate),expression:"document.inputDate"}],staticClass:"form-control",attrs:{"type":"date"},domProps:{"value":(_vm.document.inputDate)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.document, "inputDate", $event.target.value)}}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('DeleteResetSave',{attrs:{"canSave":_vm.canSave,"canDelete":_vm.canDelete,"item":_vm.document},on:{"reset":_vm.reset,"save":function($event){return passes(_vm.save)},"del":_vm.del}})],1)]}}])}),_c('InlineMessage',{attrs:{"message":_vm.message,"error":_vm.error},on:{"update:message":function($event){_vm.message=$event}}}),_c('DocumentTable',{attrs:{"documents":_vm.documents},on:{"select":_vm.selectDocument}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }