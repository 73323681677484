








import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class AdminPage extends Vue {
  mounted() {
    // document.body.style.background = 'white';
  }
}
