import Vue from 'vue';
import App from './App.vue';
import { router } from './router';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './styles/style.css';
import 'vee-validate';

import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';

import FormGroup from './components/FormGroup.vue';
import DeleteResetSave from './components/DeleteResetSave.vue';
import DropArea from './components/DropArea.vue';
import DocumentTable from './components/DocumentTable.vue';
import InlineMessage from './components/InlineMessage.vue';
import PartnerTable from './components/PartnerTable.vue';
import NewsTable from './components/NewsTable.vue';
import AddressTable from './components/AddressTable.vue';
import LoadingIndicator from './components/LoadingIndicator.vue';
import GalleryTable from './components/GalleryTable.vue';
import PhotoTable from './components/PhotoTable.vue';

Vue.component('FormGroup', FormGroup);
Vue.component('DeleteResetSave', DeleteResetSave);
Vue.component('DropArea', DropArea);
Vue.component('DocumentTable', DocumentTable);
Vue.component('InlineMessage', InlineMessage);
Vue.component('PartnerTable', PartnerTable);
Vue.component('NewsTable', NewsTable);
Vue.component('AddressTable', AddressTable);
Vue.component('LoadingIndicator', LoadingIndicator);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('GalleryTable', GalleryTable);
Vue.component('PhotoTable', PhotoTable);

Vue.config.productionTip = false;

extend('required', {
  ...required,
  message: 'Dieses Feld darf nicht leer sein',
});

extend('email', {
  ...email,
  message: 'Bitte gültige E-Mail Adresse angeben',
});

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');
