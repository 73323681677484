export enum EntityType {
  address = 'address',
  gallery = 'gallery',
  partner = 'partner',
  news = 'news',
  document = 'document',
  user = 'user',
}

export interface EntityBase {
  updated?: Date;
  created?: Date;
}

export interface BlobEntityBase extends EntityBase {
  file?: string;
}

export interface Partner extends BlobEntityBase {
  partnerId?: number;
  name?: string;
  url?: string;
  formFile?: File | null;
}

export interface News extends EntityBase {
  newsId?: number;
  title?: string;
  content?: string;
  shortContent?: string;
  date?: string;
  dateString?: string;
  inputDate?: string;
  attachments?: Attachment[];
}

export interface Attachment extends BlobEntityBase {
  attachmentId?: number;
  title: string;
  formFile?: File;
}

export interface GeneralMeeting extends EntityBase {
  generalMeetingId?: number;
  morningEventCount: number;
  morningEvent: string;
  afternoonEventCount: number;
  dinnerEventCount: number;
  dinner1: string;
  dinner2: string;
  firstName: string;
  firstEMail: string;
  isSingleMember: boolean;
  isCompanyMember: boolean;
  company: string;
  secondName: string;
  freeText: string;
}

export interface Address extends EntityBase {
  addressId?: number;
  type?: AddressType;
  company?: string;
  salutation?: string;
  title?: string;
  firstname?: string;
  lastname?: string;
  street?: string;
  zip?: string;
  city?: string;
  country?: string;
  eMailCompany?: string;
  eMailPrivate?: string;
}

export enum AddressType {
  einzelne,
  korporative,
}

export interface Document extends BlobEntityBase {
  documentId?: number;
  title?: string;
  description?: string;
  date?: string;
  dateString?: string;
  inputDate?: string;
  formFile?: File | null;
}

export interface Photo extends BlobEntityBase {
  photoId?: number;
  title: string;
  galleryId?: number;
  formFile?: File | null;
  thumbnail?: string;
  thumbnailFormFile?: File | null;
}

export interface Gallery extends EntityBase {
  galleryId?: number;
  title: string;
  date?: string;
  dateString?: string;
  inputDate?: string;
  photos: Photo[];
}

export class User {
  userId?: number;
  username?: string;
  password?: string;
  role?: string;
  token?: string;
  expiration?: string;
}

export interface Store {
  galleries: Gallery[];
  currentUser: User | null;
  loading: boolean;
}

export const Role = {
  Admin: 'admin',
  User: 'user',
};
