







import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch, PropSync } from 'vue-property-decorator';

@Component
export default class App extends Vue {
  @PropSync('message') syncedMessage!: string;
  @Prop(Boolean) error;
  messageVisible = false;

  @Watch('message') messageChanged(val) {
    if (val) {
      this.messageVisible = true;
      setTimeout(() => {
        this.messageVisible = false;
        this.syncedMessage = '';
      }, 2000);
    }
  }
}
