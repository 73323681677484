




























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Partner } from '../models/models';

@Component
export default class PartnerTable extends Vue {
  @Prop(Array) readonly partners!: Partner[];

  selectPartner(partner) {
    this.$emit('select', partner);
  }
}
