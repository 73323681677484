












































import Vue from 'vue';
import Component from 'vue-class-component';
import { Document } from '../models/models';
import {
  isEqual,
  getDocuments,
  postDocument,
  deleteDocument,
  putDocument,
} from '@/api/api';
import { format } from 'date-fns';
import { ValidationProvider } from 'vee-validate';

@Component
export default class DocumentsAdmin extends Vue {
  $refs!: {
    drop: InstanceType<typeof ValidationProvider>;
  };
  documents: Document[] = [];
  document: Document = this.emptyDocument();

  message: string = '';
  error: boolean = false;

  emptyDocument(): Document {
    return {
      formFile: null,
      inputDate: format(new Date(), 'yyyy-MM-dd'),
    };
  }

  selectDocument(document) {
    this.document = { ...document };
    scroll(0, 0);
  }

  dropped(e) {
    this.document.formFile = e[0];
  }

  reset() {
    this.document = this.emptyDocument();
  }

  get canSave() {
    return true;
  }

  save() {
    if (!(this.document.formFile || this.document.file)) {
      this.$refs.drop.setErrors(['Bitte Datei hinzufügen']);
      return;
    }

    this.document.date = this.document.inputDate;
    if (this.document.documentId) {
      putDocument(this.document)
        .then(res => {
          this.error = false;
          this.message = 'Update Erfolgreich';
        })
        .then(() => this.getDocuments())
        .catch(err => {
          this.error = true;
          this.message = 'Beim updaten ist ein Fehler aufgetreten';
        });
    } else {
      postDocument(this.document)
        .then(res => {
          this.error = false;
          this.message = 'Erfolgreich gespeichert';
        })
        .then(() => this.getDocuments())
        .catch(err => {
          this.error = true;
          this.message = 'Dokument konnte nicht gespeichert werden';
        });
    }
  }

  get canDelete() {
    return this.document.documentId ? true : false;
  }

  del() {
    deleteDocument(this.document)
      .then(res => {
        this.error = false;
        this.message = 'Erfolgreich gelöscht';
      })
      .then(() => this.getDocuments())
      .catch(err => {
        this.error = true;
        this.message = 'Fehler beim löschen';
      });
  }

  getDocuments() {
    getDocuments().then(res => (this.documents = res));
    this.reset();
  }

  mounted() {
    this.getDocuments();
  }
}
