























import Vue from 'vue';
import Component from 'vue-class-component';
import { Gallery } from '../models/models';
import { getGalleries } from '../api/api';
import store from '../store';

@Component
export default class PhotosPage extends Vue {
  mounted() {
    getGalleries();
  }

  get galleries() {
    return store.galleries;
  }
}
