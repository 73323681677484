import {
  Partner,
  News,
  Address,
  Document,
  Gallery,
  User,
  EntityBase,
  EntityType,
  Photo,
  GeneralMeeting,
} from '../models/models';
import axios from 'axios';
import { AxiosPromise } from 'axios';
import store from '../store';
import { router } from '../router';
import { format } from 'date-fns';

const maxContentLength = 250;

enum Endpoints {
  address = '/address/',
  gallery = '/gallery/',
  partner = '/partner/',
  news = '/news/',
  document = '/document/',
  user = '/user/',
  photo = '/photo/',
  generalMeeting = '/generalMeeting',
}

export let instance = axios.create({
  withCredentials: true,
  // baseURL: `${window.location.protocol}://${window.location.host}${
  //   window.location.port ? ':' + window.location.port : ''
  // }`,
  timeout: 100000,
});

if (window.location.hostname === 'localhost') {
  instance = axios.create({
    baseURL: 'http://localhost:3000',
    timeout: 100000,
    withCredentials: true,
  });
}

instance.interceptors.request.use(
  config => {
    store.loading = true;
    const currentUser = store.currentUser;

    return config;
  },
  err => {
    store.loading = false;
    Promise.reject(err);
  },
);

instance.interceptors.response.use(
  res => {
    store.loading = false;
    return res;
  },
  err => {
    store.loading = false;
    if (err.response.status === 401) {
      router.push('/intern/login');
    } else {
      return Promise.reject(err);
    }
  },
);

export const getUserFake = async (): Promise<void> => {
  const res = await instance.get(Endpoints.user);
};

export const getGeneralMeeting = async (): Promise<GeneralMeeting[]> => {
  const res = await instance.get<GeneralMeeting[]>(Endpoints.generalMeeting);
  return res.data;
};

export const postGeneralMeeting = (
  generalMeeting: GeneralMeeting,
): AxiosPromise => {
  return instance.post(Endpoints.generalMeeting, generalMeeting);
};

export const getPartners = async (): Promise<Partner[]> => {
  const res = await instance.get<Partner[]>(Endpoints.partner);
  return res.data;
};

export const getNews = async (): Promise<News[]> => {
  const res = await instance.get<News[]>(Endpoints.news);
  const data = res.data;
  data.map(x => {
    if (x.content && x.content.length > maxContentLength) {
      x.shortContent = x.content.substring(0, maxContentLength) + '...';
    } else {
      x.shortContent = x.content;
    }
  });

  data.sort((a, b) => {
    return a.date && b.date ? Date.parse(b.date) - Date.parse(a.date) : 0;
  });

  data.reverse();

  data.map(
    x => (x.dateString = new Date(x.date || new Date()).toLocaleDateString()),
  );
  data.map(x => {
    x.inputDate = format(new Date(x.date || new Date()), 'yyyy-MM-dd');
  });

  return data;
};

export const getAddresses = async (): Promise<Address[]> => {
  const res = await instance.get<Address[]>(Endpoints.address);
  const data = res.data;
  data.sort((a, b) => {
    if (
      a.company !== null &&
      a.company !== undefined &&
      b.company !== null &&
      b.company !== undefined
    ) {
      const companyA = a.company.toLowerCase();
      const companyB = b.company.toLowerCase();
      if (companyA < companyB) {
        return -1;
      } else if (companyA > companyB) {
        return 1;
      } else {
        if (a.lastname && b.lastname) {
          const lastnameA = a.lastname.toLowerCase();
          const lastnameB = b.lastname.toLowerCase();

          if (lastnameA < lastnameB) {
            return -1;
          } else if (lastnameA > lastnameB) {
            return 1;
          } else {
            return 0;
          }
        } else {
          return -1;
        }
      }
    } else {
      return -1;
    }
  });
  return res.data;
};

export const getDocuments = async (): Promise<Document[]> => {
  const res = await instance.get<Document[]>(Endpoints.document);
  const data = res.data;

  data.sort((a, b) => {
    return a.date && b.date ? Date.parse(b.date) - Date.parse(a.date) : 0;
  });

  data.map(
    x => (x.dateString = new Date(x.date || new Date()).toLocaleDateString()),
  );
  data.map(x => {
    x.inputDate = format(new Date(x.date || new Date()), 'yyyy-MM-dd');
  });

  return data;
};

export const getGalleries = async (force: boolean = false) => {
  const res = await instance.get<Gallery[]>(Endpoints.gallery);
  const data = res.data;

  data.sort((a, b) => {
    return a.date && b.date ? Date.parse(b.date) - Date.parse(a.date) : 0;
  });

  data.map(
    x => (x.dateString = new Date(x.date || new Date()).toLocaleDateString()),
  );
  data.map(x => {
    x.inputDate = format(new Date(x.date || new Date()), 'yyyy-MM-dd');
  });

  store.galleries = data;

  return data;
};

export const get = async (entityType: EntityType): Promise<EntityBase> => {
  const res = await instance.get('/' + entityType + '/');
  return res.data;
};

export const postAddress = (address: Address): AxiosPromise => {
  return instance.post(Endpoints.address, address);
};

export const putAddress = (address: Address): AxiosPromise => {
  return instance.put(Endpoints.address, address);
};
export const deleteAddress = (address: Address): AxiosPromise => {
  return instance.delete(Endpoints.address + address.addressId);
};

export const postPartner = (partner: Partner): AxiosPromise => {
  return instance.post(
    Endpoints.partner,
    getFormData(partner),
    multipartHeader,
  );
};

export const putPartner = (partner: Partner): AxiosPromise => {
  return instance.put(Endpoints.partner, getFormData(partner), multipartHeader);
};

export const deletePartner = (partner: Partner): AxiosPromise => {
  return instance.delete(Endpoints.partner + partner.partnerId);
};

export const postNews = (news: News): AxiosPromise => {
  return instance.post(Endpoints.news, getNewsFormData(news), multipartHeader);
};

export const putNews = (news: News): AxiosPromise => {
  return instance.put(Endpoints.news, getNewsFormData(news), multipartHeader);
};

export const deleteNews = (news: News): AxiosPromise => {
  return instance.delete(Endpoints.news + news.newsId);
};

export const postDocument = (document: Document): AxiosPromise => {
  return instance.post(
    Endpoints.document,
    getFormData(document),
    multipartHeader,
  );
};

export const putDocument = (document: Document): AxiosPromise => {
  return instance.put(
    Endpoints.document,
    getFormData(document),
    multipartHeader,
  );
};

export const deleteDocument = (document: Document): AxiosPromise => {
  return instance.delete(Endpoints.document + document.documentId);
};

export const postGallery = (gallery: Gallery): AxiosPromise => {
  return instance.post(Endpoints.gallery, gallery);
};

export const putGallery = (gallery: Gallery): AxiosPromise => {
  return instance.put(Endpoints.gallery, gallery);
};

export const deleteGallery = (gallery: Gallery): AxiosPromise => {
  return instance.delete(Endpoints.gallery + gallery.galleryId);
};

export const postPhoto = (photo: Photo): AxiosPromise => {
  return instance.post(Endpoints.photo, getFormData(photo), multipartHeader);
};

export const putPhoto = (photo: Photo): AxiosPromise => {
  return instance.put(Endpoints.photo, getFormData(photo), multipartHeader);
};

export const deletePhoto = (photo: Photo): AxiosPromise => {
  return instance.delete(Endpoints.photo + photo.photoId);
};

export const getPhoto = (id: number): AxiosPromise => {
  return instance.get(Endpoints.photo + id);
};

export const loginUser = async (user: User) => {
  const res = await instance.post<User>(Endpoints.user, user);
  const data = res.data;

  if (data.password) {
    delete data.password;
  }

  localStorage.setItem('currentUser', JSON.stringify(data));
  loadUser();

  return data;
};

export const logoutUser = () => {
  localStorage.removeItem('currentUser');
  store.currentUser = null;
};

export const loadUser = () => {
  const currentUserString = localStorage.getItem('currentUser');
  if (currentUserString) {
    const currentUser = JSON.parse(currentUserString);

    if (currentUser) {
      store.currentUser = currentUser;
    }
  }
};

const getFormData = (entity: any): FormData => {
  const formData = new FormData();
  Object.keys(entity).forEach(key => {
    if (entity[key] !== null) {
      formData.append(key, entity[key]);
    }
  });
  return formData;
};

const getNewsFormData = (entity: News): FormData => {
  const formData = new FormData();

  Object.keys(entity).forEach(key => {
    if (entity[key] !== null && key !== 'attachments') {
      formData.append(key, entity[key]);
    }
  });

  if (!entity.attachments) {
    return formData;
  }

  for (const [i, attachment] of entity.attachments.entries()) {
    if (attachment.attachmentId) {
      formData.append(`attachmentsId`, attachment.attachmentId.toString());
      formData.append(`attachmentsFile`, attachment.file || '');
      formData.append(`attachmentsNewTitle`, attachment.title);
    } else {
      formData.append(`attachmentsFormFile`, attachment.formFile || '');
      formData.append(`attachmentsTitle`, attachment.title);
    }
  }
  return formData;
};

const multipartHeader = {
  headers: { 'Content-Type': 'multipart/form-data' },
};

export const isEqual = (a: any, b: any): boolean => {
  return JSON.stringify(a) === JSON.stringify(b);
};
