import Vue from 'vue';
import { Store } from './models/models';

const store = Vue.observable<Store>({
  galleries: [],
  currentUser: null,
  loading: false,
});

export default store;
