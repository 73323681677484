















































import Vue from 'vue';
import Component from 'vue-class-component';
import { Gallery, Photo } from '../models/models';
import { format } from 'date-fns';
import {
  getGalleries,
  deleteGallery,
  putPhoto,
  putGallery,
  postGallery,
  postPhoto,
  deletePhoto,
  getPhoto,
} from '../api/api';
import { resizeImgFromUrl, resize } from '../util/resizer';
import axios from 'axios';
import store from '../store';

@Component
export default class PhotoAdmin extends Vue {
  gallery: Gallery = this.emptyGallery();
  galleries: Gallery[] = [];

  files: File[] = [];

  message: string = '';
  error: boolean = false;

  // This method was used to create thumbnails for all images. its no longer used but maybe its usefull later to check in code
  // addMissingThumbnails() {
  //   this.gallery.photos.forEach(photo => {
  //     if (photo.file) {
  //       resizeImgFromUrl(
  //         photo.file,
  //         250,
  //         'tn_' + photo.file.substring(photo.file.lastIndexOf('/') + 1),
  //         newFile => {
  //           const newDataUrl = URL.createObjectURL(newFile);
  //           photo.thumbnail = newDataUrl;
  //           photo.thumbnailFormFile = newFile;
  //           putPhoto(photo);
  //         },
  //       );
  //     }
  //   });
  // }

  async dropped(e: File[]) {
    if (this.gallery.galleryId) {
      for (const file of e) {
        store.loading = true;
        resize(file, 600, file.name, async (newFile) => {
          const newDataUrl = URL.createObjectURL(newFile);

          const photo: Photo = {
            galleryId: this.gallery.galleryId,
            file: newDataUrl,
            thumbnailFormFile: null,
            formFile: newFile,
            title: '',
            thumbnail: '',
          };

          const res = await postPhoto(photo);
          const resGet = await getPhoto(res.data);

          resize(file, 250, 'tn_' + resGet.data.file, async (newFile2) => {
            const newDataUrl2 = URL.createObjectURL(newFile2);
            photo.thumbnail = newDataUrl2;
            photo.thumbnailFormFile = newFile2;
            photo.formFile = null;

            await putPhoto(photo);
            this.getGalleries();
          });
        });
      }
    }
  }

  deletePhoto(photo: Photo) {
    deletePhoto(photo).then(() => {
      this.getGalleries();
    });
  }

  mounted() {
    this.getGalleries();
  }

  getGalleries() {
    const gallery = this.gallery;
    return getGalleries(true).then((res) => {
      this.galleries = res;

      this.gallery =
        this.galleries.find((x) => x.galleryId === gallery.galleryId) ||
        this.emptyGallery();
    });
  }

  emptyGallery(): Gallery {
    return {
      title: '',
      inputDate: format(new Date(), 'yyyy-MM-dd'),
      photos: [],
    };
  }

  selectGallery(gallery) {
    this.gallery = gallery;
    scroll(0, 0);
  }

  reset() {
    this.gallery = this.emptyGallery();
  }

  save(gallery) {
    this.gallery.date = this.gallery.inputDate;

    if (this.gallery.galleryId) {
      putGallery(this.gallery)
        .then((res) => {
          this.error = false;
          this.message = 'Update erfogreich';
        })
        .catch((err) => {
          this.error = true;
          this.message = 'Beim updaten is ein Fehler aufgetreten';
        })
        .finally(() => {
          this.reset();
          this.getGalleries();
        });
    } else {
      postGallery(this.gallery)
        .then((res) => {
          this.error = false;
          this.message = 'Erfolgreich gespeichert';
        })
        .catch((err) => {
          this.error = true;
          this.message = 'Galerie konnte nicht gespeichert werden';
        })
        .finally(() => {
          this.reset();
          this.getGalleries();
        });
    }
  }

  del() {
    deleteGallery(this.gallery)
      .then((res) => {
        this.error = false;
        this.message = 'Erfolgreich gelöscht';
      })
      .catch((err) => {
        this.error = true;
        this.message = 'Fehler beim löschen';
      })
      .finally(() => {
        this.reset();
        this.getGalleries();
      });
  }

  get canDelete() {
    return this.gallery.galleryId ? true : false;
  }
}
