


























































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Editor, EditorContent, EditorMenuBar } from 'tiptap';
import { News } from '../models/models';
import { isEqual, putNews, getNews, postNews, deleteNews } from '@/api/api';
import {
  Bold,
  Italic,
  Underline,
  BulletList,
  OrderedList,
  History,
  ListItem,
  Link,
} from 'tiptap-extensions';
import { Watch } from 'vue-property-decorator';
import { format } from 'date-fns';
import { ValidationProvider } from 'vee-validate';

import CustomLink from './CustomLink';

@Component({
  components: {
    EditorContent,
    EditorMenuBar,
  },
})
export default class NewsAdmin extends Vue {
  $refs!: {
    content: InstanceType<typeof ValidationProvider>;
    attachments: InstanceType<typeof ValidationProvider>;
  };

  info: News = this.emptyNews();
  news: News[] = [];
  html = '';

  message: string = 'Message';
  error = false;

  editor = new Editor({
    extensions: [
      new Bold(),
      new Italic(),
      new Underline(),
      new BulletList(),
      new ListItem(),
      new OrderedList(),
      new History(),
      new CustomLink(),
    ],
    onUpdate: ({ getJSON, getHTML }) => {
      this.setContent(getHTML());
    },
  });

  mounted() {
    this.getNews();
  }

  emptyNews(): News {
    return {
      attachments: [],
      inputDate: format(new Date(), 'yyyy-MM-dd'),
      content: '',
    };
  }

  get files() {
    if (this.info.attachments) {
      return this.info.attachments.filter(x => x.formFile !== undefined);
    }
  }

  removeAttachment(index) {
    if (this.info.attachments) {
      this.$set(
        this.info,
        'attachments',
        this.info.attachments.filter((v, i, arr) => i !== index),
      );
      this.$forceUpdate();
    }
  }

  setContent(html: string) {
    this.info.content = html;
  }

  dropped(e) {
    for (const file of e) {
      if (!this.info.attachments) {
        this.info.attachments = [];
      }
      this.info.attachments.push({ formFile: file, title: '' });
    }
  }

  get canSave() {
    return true;
  }

  get canDelete() {
    return this.info.newsId ? true : false;
  }

  save() {
    if (!this.info.content) {
      this.$refs.content.setErrors(['Bitte Inhalt hinzufügen.']);
      return;
    }
    if (
      !(
        this.info.attachments &&
        this.info.attachments.every(x => x.title !== '')
      )
    ) {
      this.$refs.attachments.setErrors([
        'Bitte Titel zu den Files hinzufügen.',
      ]);
      return;
    }

    this.info.date = this.info.inputDate;
    if (this.info.newsId) {
      putNews(this.info)
        .then(res => {
          this.error = false;
          this.message = 'Update Erfolgreich';
        })
        .catch(err => {
          this.error = true;
          this.message = 'Beim updaten ist ein Fehler aufgetreten';
        })
        .finally(() => {
          this.reset();
          this.getNews();
        });
    } else {
      postNews(this.info)
        .then(res => {
          this.error = false;
          this.message = 'Erfolgreich gespeichert';
        })
        .catch(err => {
          this.error = true;
          this.message = 'Neuigkeit konnte nicht gespeichert werden';
        })
        .finally(() => {
          this.reset();
          this.getNews();
        });
    }
  }

  getNews() {
    getNews().then(res => (this.news = res));
  }

  del() {
    deleteNews(this.info)
      .then(res => {
        this.error = false;
        this.message = 'Erfolgreich gelöscht';
      })
      .catch(err => {
        this.error = true;
        this.message = 'Fehler beim löschen';
      })
      .finally(() => {
        this.reset();
        this.getNews();
      });
  }

  reset() {
    this.info = this.emptyNews();
  }

  selectNews(news) {
    this.info = { ...news };
    scroll(0, 0);
  }

  @Watch('info') newsChanged(val, oldVal) {
    if (val.content) {
      this.editor.setContent(val.content);
    } else {
      this.editor.setContent('');
    }
  }

  beforeDestroy() {
    this.editor.destroy();
  }
}
