


















import Vue from 'vue';
import Component from 'vue-class-component';
import Navbar from '../components/Navbar.vue';
import LightBox from '../components/LightBox.vue';
import { Photo, Gallery } from '../models/models';
import { getGalleries } from '../api/api';
import store from '../store';

@Component({
  components: {
    Navbar,
    LightBox,
  },
})
export default class PhotoDetail extends Vue {
  visible = false;
  index = 0;

  get imgs() {
    if (this.currentGallery) {
      return this.currentGallery.photos.map(x => x.file);
    }
    return [];
  }

  mounted() {
    getGalleries();
  }

  get currentGallery() {
    const galleryId = parseInt(this.$route.params.galleryId, 10);
    return store.galleries.find(x => x.galleryId === galleryId);
  }

  showImg(index) {
    this.index = index;
    this.visible = !this.visible;
  }

  handleHide() {
    this.visible = !this.visible;
  }
}
