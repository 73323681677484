









import Vue from 'vue';
import Navbar from '@/components/Navbar.vue';
import Component from 'vue-class-component';
import { getUserFake } from '../api/api';

@Component({
  components: {
    Navbar,
  },
})
export default class InternPage extends Vue {
  mounted() {
    getUserFake();
  }
}
