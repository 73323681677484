export const resize = (file: File, width: number, newFilename: string, cb: (newFile: File) => void) => {
  const reader = new FileReader();

  reader.onload = () => {
    const img = new Image();
    img.onload = () => internalResize(img, width, newFilename, cb);

    if (reader.result && typeof reader.result === 'string') {
      img.src = reader.result;
    }
  };

  reader.readAsDataURL(file);
};

export const resizeImgFromUrl = (url: string, width: number, newFilename: string, cb: (newFile: File) => void) => {
  const img = new Image();
  img.crossOrigin = 'Anonymous';

  img.onload = () => internalResize(img, width, newFilename, cb);
  img.src = url;
};

const internalResize = (img: HTMLImageElement, width: number, newFilename: string, cb: (newFile: File) => void) => {
  const canvas = document.createElement('canvas');
  const scaleFactor = width / img.width;
  const height = img.height * scaleFactor;

  canvas.width = width;
  canvas.height = height;

  const ctx = canvas.getContext('2d');
  if (ctx) {
    ctx.drawImage(img, 0, 0, width, height);
    ctx.canvas.toBlob(blob => {
      if (blob) {
        const newFile = new File([blob], newFilename, {
          type: 'image/jpeg',
          lastModified: Date.now(),
        });
        cb(newFile);
      }
    });
  }
};
