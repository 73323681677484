











import Vue from 'vue';
import WelcomeItem from '@/components/WelcomeItem.vue';
import NewsList from '@/components/NewsList.vue';
import LinkList from '@/components/LinkList.vue';
import PortraitList from '@/components/PortraitList.vue';
import PartnerList from '@/components/PartnerList.vue';
import { Partner, News } from '@/models/models';
import Component from 'vue-class-component';
import { getPartners, getNews } from '../api/api';
import { shuffle } from '../util/shuffle';

@Component({
  components: {
    WelcomeItem,
    NewsList,
    LinkList,
    PortraitList,
    PartnerList,
  },
})
export default class Home extends Vue {
  partners: Partner[] = [];
  news: News[] = [];

  created() {
    getPartners().then(res => {
      this.partners = shuffle([...res]);
    });
    getNews().then(res => (this.news = res));
  }
}
