var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',[_vm._v("Anmeldung")]),_c('p',[_vm._v("zur 64. ordentlichen Generalversammlung")]),_c('strong',[_vm._v("vom 15. September 2023")]),_c('p',[_vm._v("Restaurant Aareblick, Boningen / Restaurant Salmen, Olten")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"form-horizontal"},[_c('FormGroup',{attrs:{"label":"Name*:"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.firstName),expression:"firstName"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Hans Muster"},domProps:{"value":(_vm.firstName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.firstName=$event.target.value}}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('FormGroup',{attrs:{"label":"E-Mail*:"}},[_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.firstEMail),expression:"firstEMail"}],staticClass:"form-control",attrs:{"type":"email","placeholder":"hans.muster@sepawa.ch"},domProps:{"value":(_vm.firstEMail)},on:{"input":function($event){if($event.target.composing){ return; }_vm.firstEMail=$event.target.value}}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('FormGroup',{attrs:{"label":"Firma:"}},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.company),expression:"company"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Sepawa"},domProps:{"value":(_vm.company)},on:{"input":function($event){if($event.target.composing){ return; }_vm.company=$event.target.value}}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('FormGroup',{attrs:{"label":""}},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"checkbox"},[_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.isSingleMember),expression:"isSingleMember"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.isSingleMember)?_vm._i(_vm.isSingleMember,null)>-1:(_vm.isSingleMember)},on:{"change":function($event){var $$a=_vm.isSingleMember,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.isSingleMember=$$a.concat([$$v]))}else{$$i>-1&&(_vm.isSingleMember=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.isSingleMember=$$c}}}}),_vm._v(" Einzelmitglied ")]),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('FormGroup',{attrs:{"label":""}},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"checkbox"},[_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.isCompanyMember),expression:"isCompanyMember"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.isCompanyMember)?_vm._i(_vm.isCompanyMember,null)>-1:(_vm.isCompanyMember)},on:{"change":function($event){var $$a=_vm.isCompanyMember,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.isCompanyMember=$$a.concat([$$v]))}else{$$i>-1&&(_vm.isCompanyMember=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.isCompanyMember=$$c}}}}),_vm._v(" Firmenmitglied ")]),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('FormGroup',{attrs:{"label":""}},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"checkbox"},[_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hasSecondPerson),expression:"hasSecondPerson"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.hasSecondPerson)?_vm._i(_vm.hasSecondPerson,null)>-1:(_vm.hasSecondPerson)},on:{"change":function($event){var $$a=_vm.hasSecondPerson,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.hasSecondPerson=$$a.concat([$$v]))}else{$$i>-1&&(_vm.hasSecondPerson=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.hasSecondPerson=$$c}}}}),_vm._v(" Mit Begleitperson")]),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('FormGroup',{attrs:{"label":"Begleitperson Name:"}},[(!_vm.hasSecondPerson)?_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.secondName),expression:"secondName"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Manuela Muster","disabled":!_vm.hasSecondPerson},domProps:{"value":(_vm.secondName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.secondName=$event.target.value}}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e(),(_vm.hasSecondPerson)?_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.secondName),expression:"secondName"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Manuela Muster"},domProps:{"value":(_vm.secondName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.secondName=$event.target.value}}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e()],1),_c('FormGroup',{attrs:{"label":"Teilnehmer Rahmenprogramm:"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.morningEventCount),expression:"morningEventCount"}],staticClass:"form-control",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.morningEventCount=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"0"}},[_vm._v("0")]),_c('option',{attrs:{"value":"1"}},[_vm._v("1")]),_c('option',{attrs:{"value":"2"}},[_vm._v("2")])]),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('FormGroup',{attrs:{"label":"Rahmenprogramm:"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.morningEvent),expression:"morningEvent"}],staticClass:"form-control",attrs:{"disabled":_vm.morningEventCount < 1},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.morningEvent=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":""}}),_c('option',{attrs:{"value":"Schärer & Schläpfer"}},[_vm._v(" Besichtigung Schärer & Schläpfer ")]),_c('option',{attrs:{"value":"Flusskraftwerk Ruppoldingen"}},[_vm._v(" Besichtigung Flusskraftwerk Ruppoldingen ")])]),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('FormGroup',{attrs:{"label":"Teilnehmer Nachtessen:"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.dinnerEventCount),expression:"dinnerEventCount"}],staticClass:"form-control",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.dinnerEventCount=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"0"}},[_vm._v("0")]),_c('option',{attrs:{"value":"1"}},[_vm._v("1")]),_c('option',{attrs:{"value":"2"}},[_vm._v("2")])]),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('FormGroup',{attrs:{"label":"Nachtessen:"}},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.dinner1),expression:"dinner1"}],staticClass:"form-control",attrs:{"disabled":_vm.dinnerEventCount < 1},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.dinner1=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":""}}),_c('option',{attrs:{"value":"Fleisch"}},[_vm._v("Fleisch")]),_c('option',{attrs:{"value":"Fisch"}},[_vm._v("Fisch")]),_c('option',{attrs:{"value":"vegetarisch"}},[_vm._v("vegetarisch")])]),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('FormGroup',{attrs:{"label":"Nachtessen Begleitperson:"}},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.dinner2),expression:"dinner2"}],staticClass:"form-control",attrs:{"disabled":_vm.dinnerEventCount != 2},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.dinner2=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":""}}),_c('option',{attrs:{"value":"Fleisch"}},[_vm._v("Fleisch")]),_c('option',{attrs:{"value":"Fisch"}},[_vm._v("Fisch")]),_c('option',{attrs:{"value":"vegetarisch"}},[_vm._v("vegetarisch")])]),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('FormGroup',{attrs:{"label":"Bemerkungen:"}},[_c('ValidationProvider',[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.freeText),expression:"freeText"}],staticClass:"form-control",attrs:{"name":"freeText","id":"","cols":"30","rows":"10"},domProps:{"value":(_vm.freeText)},on:{"input":function($event){if($event.target.composing){ return; }_vm.freeText=$event.target.value}}})])],1),_c('span',[_vm._v("Sie erhalten nach der Anmeldung eine E-Mail mit den von Ihnen gemachten Angaben.")]),_c('button',{staticClass:"btn btn-success pull-right",attrs:{"type":"submit","disabled":invalid},on:{"click":function($event){$event.preventDefault();return _vm.register.apply(null, arguments)}}},[_vm._v(" Anmelden ")])],1)]}}])}),_c('InlineMessage',{attrs:{"message":_vm.message,"error":_vm.error},on:{"update:message":function($event){_vm.message=$event}}}),_c('LoadingIndicator')],1)}
var staticRenderFns = []

export { render, staticRenderFns }