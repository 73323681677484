























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { News } from '../models/models';

@Component
export default class NewsList extends Vue {
  @Prop(Array) news!: News[];
}
