




















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Component
export default class DropArea extends Vue {
  @Prop(Boolean) readonly single!: boolean;
  @Prop() readonly files!: File | File[];

  internalFiles: File[] = [];
  boxclass: string = '';

  @Watch('files') filesChanged(val, oldVal) {
    if (this.single && Array.isArray(val) === false && val) {
      this.internalFiles = [val];
    } else if (Array.isArray(val)) {
      this.internalFiles = [...val];
    } else {
      this.internalFiles = [];
    }
  }

  get label() {
    return this.single ? 'File:' : 'Files:';
  }

  dragover() {
    this.boxclass = 'is-dragover';
  }

  dragleave() {
    this.boxclass = '';
  }

  dropped(e) {
    this.$emit('dropped', e.dataTransfer.files);
  }
}
