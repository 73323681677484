

















































import Vue from 'vue';
import Component from 'vue-class-component';
import Navbar from '@/components/Navbar.vue';
import { Address } from '../models/models';
import { search } from '@/util/simpleSearch';
import { Prop } from 'vue-property-decorator';

@Component({
  components: {
    Navbar,
  },
})
export default class AddressTable extends Vue {
  @Prop(Array) addresses!: Address[];
  searchTerm = '';

  get filteredAddresses() {
    if (this.searchTerm === '') {
      return this.addresses;
    }

    const filtered = search(this.searchTerm, this.addresses, [
      'firstname',
      'lastname',
      'company',
      'street',
      'city',
    ]);
    return filtered;
  }

  selectAddress(address: Address) {
    this.$emit('select', address);
  }
}
