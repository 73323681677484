








































import Vue from 'vue';
import Component from 'vue-class-component';
import { Partner, EntityType } from '../models/models';
import { Watch } from 'vue-property-decorator';
import {
  isEqual,
  getPartners,
  putPartner,
  postPartner,
  deletePartner,
} from '../api/api';
import { validate, ValidationProvider } from 'vee-validate';

@Component({})
export default class PartnerAdmin extends Vue {
  $refs!: {
    drop: InstanceType<typeof ValidationProvider>;
  };
  partners: Partner[] = [];
  partner: Partner = this.emptyPartner();

  message: string = '';
  error: boolean = false;

  mounted() {
    this.getPartners();
  }

  dropped(e) {
    this.partner.formFile = e[0];
  }

  emptyPartner(): Partner {
    return { formFile: null };
  }

  selectPartner(partner) {
    this.partner = { ...partner };
    scroll(0, 0);
  }

  get canSave() {
    return true;
  }

  reset() {
    this.partner = this.emptyPartner();
  }

  save() {
    if (!(this.partner.file || this.partner.formFile)) {
      this.$refs.drop.setErrors(['Bitte Datei hinzufügen']);
      return;
    }

    if (this.partner.partnerId) {
      putPartner(this.partner)
        .then((res) => {
          this.error = false;
          this.message = 'Update erfolgreich';
        })
        .catch((err) => {
          this.error = true;
          this.message = 'Beim updaten is ein Fehler aufgetreten';
        })
        .finally(() => {
          this.reset();
          this.getPartners();
        });
    } else {
      postPartner(this.partner)
        .then((res) => {
          this.error = false;
          this.message = 'Erfolgreich gespeichert';
        })
        .catch((err) => {
          this.error = true;
          this.message = 'Partner konnte nicht gespeichert werden';
        })
        .finally(() => {
          this.reset();
          this.getPartners();
        });
    }
  }

  getPartners() {
    getPartners().then((res) => {
      this.partners = res;
    });
  }

  del() {
    deletePartner(this.partner)
      .then((res) => {
        this.error = false;
        this.message = 'Erfolgreich gelöscht';
      })
      .catch((err) => {
        this.error = true;
        this.message = 'Fehler beim löschen';
      })
      .finally(() => {
        this.reset();
        this.getPartners();
      });
  }

  get canDelete() {
    return this.partner.partnerId ? true : false;
  }

  get sortedPartners() {
    const unsorted = [...this.partners];
    unsorted.sort((a, b) => {
      const valA = a.name ? a.name.toLowerCase() : '';
      const valB = b.name ? b.name.toLowerCase() : '';
      if (valA < valB) {
        return -1;
      } else if (valA > valB) {
        return 1;
      } else {
        return 0;
      }
    });

    return unsorted;
  }
}
