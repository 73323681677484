

























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Photo } from '../models/models';

@Component
export default class PhotoTable extends Vue {
  @Prop(Array) photos!: Photo[];

  del(item) {
    this.$emit('del', item);
  }
}
