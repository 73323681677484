


















import Vue from 'vue';
import PartnerItem from '@/components/PartnerItem.vue';
import Component from 'vue-class-component';
import { Partner } from '../models/models';
import { Prop } from 'vue-property-decorator';
import { shuffle } from '../util/shuffle';

@Component({
  components: {
    PartnerItem,
  },
})
export default class PartnerList extends Vue {
  @Prop(Array) partners!: Partner[];
}
