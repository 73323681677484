







import Vue from 'vue';
import Component from 'vue-class-component';
import { Document } from '../models/models';
import { getDocuments } from '../api/api';

@Component
export default class DocumentsPage extends Vue {
  documents: Document[] = [];

  mounted() {
    getDocuments().then(res => (this.documents = res));
  }
}
