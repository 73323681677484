






























import Vue from 'vue';
import Component from 'vue-class-component';
import { Role } from '../models/models';
import { logoutUser } from '../api/api';
import store from '../store';

@Component
export default class SelectionIntern extends Vue {
  logoutUser() {
    logoutUser();
    this.$router.push('/');
  }

  get currentUser() {
    return store.currentUser;
  }
}
