export const search = <T extends {}, K extends keyof T>(term: string, objects: T[], keys: K[]): T[] => {
  return objects.filter(x => {
    return keys.some(key => {
      if (x.hasOwnProperty(key) === false) {
        return false;
      }
      const val = x[key];
      if (typeof val === 'string' && val.toLowerCase().includes(term.toLowerCase())) {
        return true;
      }
      return false;
    });
  });
  // return objects.filter(x =>
  //   keys.some(key => x.hasOwnProperty(key) && typeof x[key] === 'string' && x[key].toLowerCase().includes(term)),
  // );
};
