

















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { News } from '../models/models';
import { format } from 'date-fns';

@Component
export default class NewsTable extends Vue {
  @Prop(Array) news!: News[];

  selectNews(news) {
    this.$emit('select', news);
  }
}
