






















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Gallery } from '../models/models';

@Component
export default class GalleryTable extends Vue {
  @Prop(Array) galleries!: Gallery[];

  selectGallery(gallery) {
    this.$emit('select', gallery);
  }
}
