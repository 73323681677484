



























import Vue from 'vue';
import Component from 'vue-class-component';
import { getGeneralMeeting } from '../api/api';
import Excel from 'exceljs/dist/es5/exceljs.bare';
import { saveAs } from 'file-saver';

@Component
export default class SelectAdmin extends Vue {
  async downloadGeneralMeetingExcel() {
    const generalMeetingData = await getGeneralMeeting();

    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Generalversammlung');

    const firstRow = worksheet.addRow([
      'Index',
      'Name',
      'E-Mail',
      'Firma',
      'Einzelmitglied',
      'Firmenmitglied',
      'Begleitperson',
      'Vormittagsprogramm Anzahl',
      'Vormittagsprogramm',
      'Nachtessen',
      'Nachtessen Person 1',
      'Nachtessen Person 2',
      'Bemerkungen',
    ]);
    firstRow.font = { bold: true };

    const values = generalMeetingData.map((x, index) => {
      return Object.values([
        index,
        x.firstName,
        x.firstEMail,
        x.company,
        x.isSingleMember,
        x.isCompanyMember,
        x.secondName,
        x.morningEventCount,
        x.morningEvent,
        x.dinnerEventCount,
        x.dinner1,
        x.dinner2,
        x.freeText,
      ]);
    });

    values.forEach(x => {
      worksheet.addRow(x);
    });

    const buffer = await workbook.xlsx.writeBuffer();

    saveAs(new Blob([buffer]), 'Generalversammlung.xlsx');
  }
}
