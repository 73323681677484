






import Vue from 'vue';
import Component from 'vue-class-component';
import { loadUser } from './api/api';

@Component
export default class App extends Vue {
  mounted() {
    loadUser();
  }
}
