





















































import Vue from 'vue';
import $ from 'jquery';

export default Vue.extend({
});
