





































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { postGeneralMeeting } from '../api/api';
import { GeneralMeeting } from '../models/models';

@Component
export default class GeneralMeetingForm extends Vue {
  firstName: string = '';
  firstEMail: string = '';
  isSingleMember: boolean = false;
  isCompanyMember: boolean = false;
  company: string = '';
  hasSecondPerson: boolean = false;

  secondName: string = '';

  morningEventCount: number = 1;
  morningEvent: string = '';
  afternoonEventCount: number = 1;
  dinnerEventCount: number = 1;
  dinner1: string = '';
  dinner2: string = '';
  freeText: string = '';

  message = '';
  error = false;

  register() {
    const generalMeeting: GeneralMeeting = {
      firstName: this.firstName,
      firstEMail: this.firstEMail,
      isSingleMember: this.isSingleMember,
      isCompanyMember: this.isCompanyMember,
      company: this.company,
      secondName: this.secondName,
      morningEventCount: this.morningEventCount,
      morningEvent: this.morningEvent,
      afternoonEventCount: this.afternoonEventCount,
      dinnerEventCount: this.dinnerEventCount,
      dinner1: this.dinner1,
      dinner2: this.dinner2,
      freeText: this.freeText,
    };

    postGeneralMeeting(generalMeeting)
      .then(res => {
        this.error = false;
        this.message = 'Anmeldung erfolgreich';
        this.reset();

        this.$router.push('/intern/success');
      })
      .catch(err => {
        this.error = true;
        this.message =
          'Anmeldung nicht erfolgreich! Bitte versuchen Sie es später nochmals oder melden Sie sich per E-Mail an.';
        this.$router.push('/intern/error');
      });
  }

  reset() {
    this.firstName = '';
    this.firstEMail = '';
    this.isSingleMember = false;
    this.isCompanyMember = false;
    this.company = '';
    this.secondName = '';
    this.morningEventCount = 1;
    this.afternoonEventCount = 1;
    this.dinnerEventCount = 1;
    this.dinner1 = '';
    this.dinner2 = '';
    this.freeText = '';
  }
}
