





















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { isEqual } from '../api/api';

@Component
export default class DeleteResetSave extends Vue {
  @Prop(Object) item;
  @Prop({ default: false }) canDelete;
  @Prop({ default: false }) canSave;

  save(item) {
    this.$emit('save');
  }

  update(item) {
    this.$emit('update');
  }

  del(item) {
    this.$emit('del');
  }

  reset(item) {
    this.$emit('reset');
  }
}
