





































import Vue from 'vue';
import Component from 'vue-class-component';
import { User } from '../models/models';
import { loginUser } from '../api/api';

@Component
export default class Template extends Vue {
  username: string = '';
  password: string = '';
  message: string = '';
  error: boolean = true;

  loginUser() {
    const user: User = {
      username: this.username,
      password: this.password,
      expiration: new Date().toISOString(),
    };

    loginUser(user)
      .then(res => {
        this.$router.push('/intern');
      })
      .catch(err => {
        this.error = true;
        this.message = 'Benutzername oder Passwort sind falsch.';
      });
  }
}
