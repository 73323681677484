






import Vue from 'vue';
import Component from 'vue-class-component';
import AddressesPage from '@/views/AddressesPage.vue';
import AddressForm from '@/components/AddressForm.vue';
import { Address } from '../models/models';

@Component({
  components: {
    AddressesPage,
    AddressForm,
  },
})
export default class AddressesAdmin extends Vue {}
