






























import Vue from 'vue';
import Navbar from '@/components/Navbar.vue';
import PartnerItem from '@/components/PartnerItem.vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { shuffle } from '@/util/shuffle';

@Component({
  components: {
    Navbar,
    PartnerItem,
  },
})
export default class WelcomeItem extends Vue {
  @Prop(Array) readonly partners;

  get featuredPartners() {
    if (this.partners) {
      return this.partners.slice(0, 4);
    } else {
      return [];
    }
  }
}
