








import Vue from 'vue';
import { Partner } from '@/models/models';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class PartnerItem extends Vue {
  @Prop(Object) partner;
}
