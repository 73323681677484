import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
import InternPage from './views/InternPage.vue';
import AddressesPage from './views/AddressesPage.vue';
import NewsDetail from './views/NewsDetail.vue';
import DocumentsPage from './views/DocumentsPage.vue';
import PhotosPage from './views/PhotosPage.vue';
import PhotoDetail from './views/PhotoDetail.vue';
import AdminPage from './views/AdminPage.vue';
import SuccessPage from './views/SuccessPage.vue';
import ErrorPage from './views/ErrorPage.vue';
import AddressesAdmin from './components/AddressesAdmin.vue';
import DocumentsAdmin from './components/DocumentsAdmin.vue';
import PhotoAdmin from './components/PhotoAdmin.vue';
import NewsAdmin from './components/NewsAdmin.vue';
import PartnerAdmin from './components/PartnerAdmin.vue';
import SelectAdmin from './components/SelectAdmin.vue';
import SelectionIntern from './components/SelectionIntern.vue';
import GeneralMeetingForm from './components/GeneralMeetingForm.vue';
import Login from './components/Login.vue';
import { Role } from './models/models';
import store from './store';

Vue.use(Router);

export const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPostion) {
    // https://router.vuejs.org/guide/advanced/scroll-behavior.html
    // 500ms until we scroll to the right position. This should work most of the time to load the news.

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(savedPostion);
      }, 500);
    });
  },
  routes: [
    {
      path: '/',
      component: Home,
    },
    {
      path: '/intern',
      component: InternPage,
      meta: {
        authorize: [Role.Admin, Role.User],
      },
      children: [
        {
          path: '',
          component: SelectionIntern,
          meta: {
            authorize: [Role.Admin, Role.User],
          },
        },
        {
          path: 'login',
          component: Login,
        },
        {
          path: 'addresses',
          component: AddressesPage,
          meta: {
            authorize: [Role.Admin, Role.User],
          },
        },
        {
          path: 'documents',
          component: DocumentsPage,
          meta: {
            authorize: [Role.Admin, Role.User],
          },
        },
        {
          path: 'photos',
          component: PhotosPage,
          meta: {
            authorize: [Role.Admin, Role.User],
          },
        },
        {
          path: 'photos/:galleryId',
          component: PhotoDetail,
          meta: {
            authorize: [Role.Admin, Role.User],
          },
        },
        {
          path: 'gv',
          component: GeneralMeetingForm,
          meta: {
            authorize: [Role.Admin, Role.User],
          },
        },
        {
          path: 'success',
          component: SuccessPage,
          meta: {
            authorize: [Role.Admin, Role.User],
          },
        },
        {
          path: 'error',
          component: ErrorPage,
          meta: {
            authorize: [Role.Admin, Role.User],
          },
        },
      ],
    },
    {
      path: '/news/:newsId',
      component: NewsDetail,
    },
    {
      path: '/admin',
      component: AdminPage,
      meta: {
        authorize: [Role.Admin],
      },
      children: [
        {
          path: '',
          component: SelectAdmin,
          meta: {
            authorize: [Role.Admin],
          },
        },
        {
          path: 'Addresses',
          component: AddressesAdmin,
          meta: {
            authorize: [Role.Admin],
          },
        },
        {
          path: 'Documents',
          component: DocumentsAdmin,
          meta: {
            authorize: [Role.Admin],
          },
        },
        {
          path: 'Photos',
          component: PhotoAdmin,
          meta: {
            authorize: [Role.Admin],
          },
        },
        {
          path: 'News',
          component: NewsAdmin,
          meta: {
            authorize: [Role.Admin],
          },
        },
        {
          path: 'Partner',
          component: PartnerAdmin,
          meta: {
            authorize: [Role.Admin],
          },
        },
      ],
    },
    {
      path: '*',
      redirect: '/',
    },
  ],
});

router.beforeEach((to, from, next) => {
  const { authorize } = to.meta as any;

  let currentUser = store.currentUser;
  if (!currentUser) {
    const currentUserString = localStorage.getItem('currentUser');
    if (currentUserString) {
      currentUser = JSON.parse(currentUserString);
    }
  }

  if (authorize) {
    if (!currentUser) {
      // not logged in so redirect to login page with the return url
      return next({ path: '/intern/login', query: { returnUrl: to.path } });
    }

    // check if route is restricted by role
    if (authorize.length && !authorize.includes(currentUser.role)) {
      return next({ path: '/intern/login', query: { returnUrl: to.path } });
    }
  }

  next();
});
