




















































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { Address, AddressType } from '../models/models';
import {
  isEqual,
  getAddresses,
  deleteAddress,
  postAddress,
  putAddress,
} from '../api/api';

@Component
export default class AddressForm extends Vue {
  message: string = '';
  error = false;
  address: Address = {};
  addresses: Address[] = [];

  mounted() {
    this.getAddresses();
  }

  save() {
    if (this.address.addressId) {
      putAddress(this.address)
        .then(res => {
          this.error = false;
          this.message = 'Update erfolgreich';
        })
        .catch(err => {
          this.error = true;
          this.message = 'Beim updaten ist ein Fehler aufgetreten';
        })
        .finally(() => {
          this.reset();
          this.getAddresses();
        });
    } else {
      postAddress(this.address)
        .then(res => {
          this.error = false;
          this.message = 'Erfolgreich gespeichert';
        })
        .catch(err => {
          this.error = true;
          this.message = 'Adresse konnte nicht gespeichert werden';
        })
        .finally(() => {
          this.reset();
          this.getAddresses();
        });
    }
  }

  del() {
    if (this.address.addressId) {
      deleteAddress(this.address)
        .then(res => {
          this.error = false;
          this.message = 'Erfolgreich gelöscht';
        })
        .catch(err => {
          this.error = true;
          this.message = 'Fehler beim löschen';
        })
        .finally(() => {
          this.reset();
          this.getAddresses();
        });
    }
  }

  getAddresses() {
    getAddresses().then(res => (this.addresses = res));
  }

  selectAddress(address) {
    this.address = { ...address };
    scroll(0, 0);
  }

  get canSave() {
    return true;
  }

  get canDelete() {
    return this.address.addressId ? true : false;
  }

  reset() {
    this.address = {};
  }
}
