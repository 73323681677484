
























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class Template extends Vue {
  @Prop(Array) documents;

  selectDocument(document) {
    this.$emit('select', document);
  }
}
