


























import Vue from 'vue';

import Navbar from '@/components/Navbar.vue';
import Component from 'vue-class-component';
import { News } from '../models/models';
import { getNews } from '../api/api';

@Component({
  components: {
    Navbar,
  },
})
export default class NewsDetail extends Vue {
  selectedNews: News | undefined = {};

  mounted() {
    getNews().then(res => {
      const newsId = parseInt(this.$route.params.newsId, 10);
      this.selectedNews = res.find(x => x.newsId === newsId);
      this.$forceUpdate();
    });
  }
}
