var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('form',{staticClass:"form-horizontal"},[_c('FormGroup',{attrs:{"label":"Titel:"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.info.title),expression:"info.title"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.info.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.info, "title", $event.target.value)}}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('FormGroup',{attrs:{"label":"Inhalt:"}},[_c('ValidationProvider',{ref:"content",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('editor-menu-bar',{staticClass:"mb-5",attrs:{"editor":_vm.editor},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var commands = ref.commands;
var isActive = ref.isActive;
return [_c('div',{staticClass:"btn-group",attrs:{"role":"group"}},[_c('button',{class:"btn btn-default {'is-active': isActive.bold()}",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return commands.bold.apply(null, arguments)}}},[_c('span',{staticClass:"glyphicon glyphicon-bold"})]),_c('button',{class:"btn btn-default {'is-active': isActive.italic()}",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return commands.italic.apply(null, arguments)}}},[_c('span',{staticClass:"glyphicon glyphicon-italic"})]),_c('button',{class:"btn btn-default {'is-active': isActive.underline()}",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return commands.underline.apply(null, arguments)}}},[_c('span',{staticClass:"glyphicon glyphicon-text-width"})]),_c('button',{class:"btn btn-default {'is-active': isActive.bullet_list()}",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return commands.bullet_list.apply(null, arguments)}}},[_c('span',{staticClass:"glyphicon glyphicon-list"})]),_c('button',{class:"btn btn-default {'is-active': isActive.ordered_list()}",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return commands.ordered_list.apply(null, arguments)}}},[_c('span',{staticClass:"glyphicon glyphicon-sort-by-attributes"})])])]}}],null,true)}),_c('div',{staticClass:"editor-content"},[_c('editor-content',{staticClass:"fixed-height",attrs:{"editor":_vm.editor}})],1),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('ValidationProvider',{ref:"attachments",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DropArea',{attrs:{"files":_vm.files},on:{"dropped":_vm.dropped}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._l((_vm.info.attachments),function(attachment,index){return _c('div',{key:index},[_c('FormGroup',{attrs:{"label":"Titel:"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(attachment.title),expression:"attachment.title"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(attachment.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(attachment, "title", $event.target.value)}}}),(attachment.formFile)?_c('div',[_vm._v(_vm._s(attachment.formFile.name))]):_c('div',[_vm._v(_vm._s(attachment.title))]),_c('button',{staticClass:"btn btn-danger",on:{"click":function($event){$event.preventDefault();return _vm.removeAttachment(index)}}},[_vm._v(" Anhang entfernen ")])])],1)}),_c('FormGroup',{attrs:{"label":"Datum:"}},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.info.inputDate),expression:"info.inputDate"}],staticClass:"form-control",attrs:{"type":"date"},domProps:{"value":(_vm.info.inputDate)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.info, "inputDate", $event.target.value)}}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('DeleteResetSave',{attrs:{"canSave":_vm.canSave,"canDelete":_vm.canDelete,"item":_vm.info},on:{"reset":_vm.reset,"save":function($event){return passes(_vm.save)},"del":_vm.del}})],2)]}}])}),_c('InlineMessage',{attrs:{"message":_vm.message,"error":_vm.error},on:{"update:message":function($event){_vm.message=$event}}}),_c('NewsTable',{attrs:{"news":_vm.news},on:{"select":_vm.selectNews}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }